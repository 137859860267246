(function () {
  const quickFetch = window && window.quickFetch;
  if (!quickFetch) {
    return;
  }
  const queryParams = new URLSearchParams({
    limit: '1',
    includeDeleted: 'true',
    property: 'id',
    // email-management-lib/static/js/refactor/constants/manage/frameworkTable.ts:182:CRM_SEARCH_HIDDEN_STATE
    state__nin: ['DRAFT_AB_VARIANT', 'PUBLISHED_AB_VARIANT', 'AUTOMATED_DRAFT_ABVARIANT', 'AUTOMATED_AB_VARIANT', 'AUTOMATED_LOSER_ABVARIANT', 'AUTOMATED_FOR_FORM_BUFFER'].join(','),
    // email-management-lib/static/js/refactor/constants/manage/frameworkTable.ts:191:CRM_SEARCH_HIDDEN_SUBCATEGORY
    subcategory__nin: ['automated_for_leadflow', 'automated_for_ticket', 'ticket_closed_kickback_email', 'ticket_opened_kickback_email', 'blog_email_child', 'rss_to_email_child'].join(','),
    // email-management-lib/static/js/refactor/constants/manage/frameworkTable.ts:200:CRM_SEARCH_HIDDEN_AB_TEST_STATUS
    ab_test_status__nin: ['VARIANT', 'AUTOMATED_VARIANT', 'AUTOMATED_LOSER_VARIANT'].join(',')
  });

  // email-management-lib/static/js/earlyRequest/constants.ts:HAS_CREATED_EMAILS_EARLY_REQUEST_ID
  quickFetch.makeEarlyRequest('has-created-emails', {
    url: quickFetch.getApiUrl(`/cosemail/v1/emails?${queryParams}`, true),
    dataType: 'json',
    contentType: 'application/json',
    type: 'GET',
    objectTypeId: '0-29',
    offset: 0
  });
})();